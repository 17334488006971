import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'ui/atoms';
import ContextButton from 'ui/molecules/ContextButton';

import { ReactComponent as IconCaretDown } from './caret-down.svg';

import './gpt-model-selection.css';

export type LLModel = {
  id: string;
  name: string;
  displayName: string;
  description: string;
  maxTokenCount?: number;
  modelType: string;
  disabled: boolean;
};

const GptModelSelection = ({ setGptModel, gptModel, gptModels }) => {
  const [showModal, setShowModal] = useState(false);
  const [gptModelName, setGptModelName] = useState('');

  useEffect(() => {
    if (!gptModels?.length) {
      return;
    }

    const currentModel = gptModels.find((item) => item.id === gptModel);

    const name = currentModel?.name;
    const displayName = currentModel?.displayName || name;

    if (!name) {
      setGptModel(gptModels[0]?.id);
      return;
    }

    setGptModelName(displayName);
  }, [gptModel, gptModels, setGptModel]);

  return (
    <div className="model-selection">
      <Button
        onClick={() => {
          setShowModal(true);
        }}
        type={'default'}
        data-testid="model-selection"
      >
        {gptModelName} <IconCaretDown />
      </Button>
      <Modal
        title="Select AI Model"
        open={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[]}
      >
        <div className="gpt-model-selection">
          <div className="gpt-model-list">
            {gptModels &&
              gptModels.length > 0 &&
              gptModels.map((model) => {
                return (
                  <div
                    className="gpt-model-item"
                    key={`gpt-selection-${model.id}`}
                  >
                    {model.modelType}
                    <ContextButton
                      isSelected={model.id === gptModel}
                      title={model.displayName}
                      text={model.description}
                      value={model.id}
                      disabled={model.disabled}
                      setSelected={(value) => {
                        setGptModel(value);
                        setShowModal(false);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GptModelSelection;
