import { useEffect, useRef, useState } from 'react';

import { setIsMessagesWindowScrolling } from 'stores/app-context';
import { FileType, mapFileIdToFile } from 'utils/file-utils';

import ConversationMessageRow from '../ConversationMessageRow';
import ConversationResponseRow from '../ConversationResponseRow';
import ResponseFeedback from '../ResponseFeedback';
import ResubmitButton from '../ResubmitButton';

import './conversation-message-display.css';

type ConversationMessageDisplayProps = {
  messages: any[];
  messagesEndRef: any;
  resubmitAction?: (errorMessageId) => void;
  currentConversationId?: string;
  onSelectFileClick?: any;
  personalFiles: FileType[];
};

const ConversationMessageDisplay = ({
  messages,
  messagesEndRef,
  resubmitAction,
  currentConversationId,
  onSelectFileClick,
  personalFiles,
}: ConversationMessageDisplayProps) => {
  const messagesWindowRef = useRef<HTMLDivElement | null>(null);
  const handleScrollRef = useRef<() => void>(() => {});

  const [displayMessages, setDisplayMessages] = useState(messages);

  handleScrollRef.current = () => {
    if (messagesWindowRef.current) {
      setIsMessagesWindowScrolling(messagesWindowRef.current.scrollTop > 10);
    }
  };

  useEffect(() => {
    let displayedFiles = [] as any[];
    let newMessages = messages.map((m) => {
      const newM = { ...m };
      const filesToDisplay =
        m.associatedFileRecordIds?.filter((file) => {
          if (!displayedFiles.includes(file)) {
            displayedFiles.push(file);
            return true;
          }
          return false;
        }) || [];
      newM.filesToDisplay = filesToDisplay
        .map((id) => mapFileIdToFile(id, personalFiles))
        .filter(
          (f) =>
            f.processingStatus === 'done' || f.processingStatus === 'deleted',
        );
      return newM;
    });
    setDisplayMessages(newMessages);
  }, [messages, personalFiles]);

  useEffect(() => {
    const messagesWindow = messagesWindowRef.current;

    if (!messagesWindow || !handleScrollRef.current) {
      return;
    }

    const handleScroll = () => {
      handleScrollRef.current();
    };

    messagesWindow.addEventListener('scroll', handleScroll);

    return () => messagesWindow.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      ref={messagesWindowRef}
      className="messages-window"
      data-testid="messages-window"
    >
      {displayMessages.map((m, i) => (
        <div
          className="message-item"
          key={`message_${m.messageId}${'_' + m.generationStatus}`}
          data-testid="message-item"
        >
          {i % 2 === 0 && (
            <ConversationMessageRow
              message={m.message}
              onSelectFileClick={onSelectFileClick}
              associatedFiles={m.filesToDisplay}
            />
          )}
          {i % 2 !== 0 && (
            <>
              <ConversationResponseRow
                message={m.message}
                profileImg={m.profileImg}
                isError={m.generationStatus === 'error'}
              />
              {m.generationStatus === 'done' && (
                <ResponseFeedback
                  defaultReaction={m.userFeedback?.feedbackType}
                  conversationId={currentConversationId}
                  message={m}
                  updateReaction={() => {}}
                />
              )}
              {m.generationStatus === 'error' &&
                m.allowRetry &&
                resubmitAction && (
                  <ResubmitButton resubmitAction={resubmitAction} />
                )}
            </>
          )}
        </div>
      ))}
      <div className="messages-end" ref={messagesEndRef} />
    </div>
  );
};

export default ConversationMessageDisplay;
