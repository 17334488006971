import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

import {
  CurrentConversationStore,
  setApiConfig,
  sendMessage,
  resubmitLastMessage,
} from 'stores/current-conversation';
import { loadConversationHistory } from 'stores/conversation-history';
import { UserStore, getPersonalFiles } from 'stores/user';

import {
  QuestionInput,
  GoldieLogo,
  ConversationControls,
  ConversationMessageDisplay,
} from 'ui/molecules';

import FileSelectorModal from '../FileSelector';
import FileUploadModal from '../FileUpload';

import './conversation.css';

const Conversation = () => {
  const messagesEndRef = useRef(null);

  const apiConfig = UserStore.useState((s) => s.apiConfig);
  const personalFiles = UserStore.useState((s) => s.personalFiles);

  const messages = CurrentConversationStore.useState((s) => s.messages);
  const currentConversationId = CurrentConversationStore.useState((s) => s.id);
  const conversationIsLoading = CurrentConversationStore.useState(
    (s) => s.isLoading,
  );

  const [fileSelectorVisible, setFileSelectorVisible] =
    useState<boolean>(false);

  const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);

  useEffect(() => {
    if (apiConfig) {
      setApiConfig(apiConfig);
      getPersonalFiles();
    }
  }, [apiConfig]);

  useEffect(() => {
    loadConversationHistory();
  }, [currentConversationId]);

  useEffect(() => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        (messagesEndRef.current as HTMLElement).scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 200);
  }, [messages]);

  return (
    <div className="conversation-area">
      {!messages ||
        (messages.length === 0 && !conversationIsLoading && (
          <div className="center-area">
            <GoldieLogo monochrome />
          </div>
        ))}

      {conversationIsLoading && messages.length === 0 && (
        <div className="center-area">
          <Spin size={'large'} />
        </div>
      )}

      {messages && messages.length > 0 && (
        <ConversationMessageDisplay
          messages={messages}
          messagesEndRef={messagesEndRef}
          resubmitAction={resubmitLastMessage}
          currentConversationId={currentConversationId}
          onSelectFileClick={() => {
            setFileSelectorVisible(true);
          }}
          personalFiles={personalFiles}
        />
      )}
      <div className="conversation-input-wrapper">
        <div className="conversation-input-controls">
          <QuestionInput
            onSelectFileClick={() => {
              setFileSelectorVisible(true);
            }}
            onSubmit={(m: string) => {
              sendMessage(m);
            }}
          />
          <ConversationControls
            onSelectFileClick={() => {
              setFileSelectorVisible(true);
            }}
            onFileUploadClick={() => {
              setFileUploadVisible(true);
            }}
          />
        </div>
      </div>

      <FileSelectorModal
        fileSelectorVisible={fileSelectorVisible}
        setFileSelectorVisible={setFileSelectorVisible}
        personalFiles={personalFiles}
      />

      <FileUploadModal
        fileUploadVisible={fileUploadVisible}
        setFileUploadVisible={setFileUploadVisible}
      />
    </div>
  );
};

export default Conversation;
