import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { CheckboxMultiSelect } from 'ui/molecules/CheckboxMultiSelect';
import CheckboxFileSelection from 'ui/molecules/CheckboxFileSelection';
import { Button } from 'ui';

import { FileType } from 'utils/file-utils';

import { ReactComponent as DeleteIcon } from './delete.svg';
import './file-selector.css';

const FileSelector = ({
  fileList,
  selectedFileIds,
  currentConversationSelectedFileIds,
  setSelectedFiles,
  setConfirmDelete,
}) => {
  const [error, setError] = useState<string>('');

  const [availableToSelectFiles, setAvailableToSelectFiles] = useState<
    FileType[]
  >([]);
  const [
    currentConversationSelectedFiles,
    setCurrentConversationSelectedFiles,
  ] = useState<any[]>([]);

  useEffect(() => {
    setAvailableToSelectFiles(
      fileList.filter(
        (file) =>
          file.processingStatus !== 'deleted' &&
          (!currentConversationSelectedFileIds.includes(file.id) ||
            file.processingStatus === 'error'),
      ),
    );

    setCurrentConversationSelectedFiles(
      fileList.filter(
        (file) =>
          currentConversationSelectedFileIds.includes(file.id) &&
          (file.processingStatus === 'done' ||
            file.processingStatus === 'deleted'),
      ),
    );
  }, [fileList, currentConversationSelectedFileIds]);

  return (
    <div
      className={classNames('file-selector', {
        'has-files-applied': currentConversationSelectedFiles.length > 0,
      })}
      data-testid="test-file-selector"
    >
      {error && <div className="error">{error}</div>}
      {fileList.length > 0 && (
        <Button
          type="link"
          aria-label="Delete File(s)"
          data-testid="delete-file-button"
          className="ant-modal-round-button delete-button"
          icon={<DeleteIcon />}
          onClick={() => {
            if (selectedFileIds.length > 0) {
              setConfirmDelete(true);
            }
          }}
        ></Button>
      )}
      {availableToSelectFiles.length > 0 && (
        <CheckboxMultiSelect
          label={'Uploaded files'}
          isChecked={selectedFileIds.length === availableToSelectFiles.length}
          isMixedSelection={
            selectedFileIds.length > 0 &&
            selectedFileIds.length !== availableToSelectFiles.length
          }
          setSelected={(event) => {
            if (event.target.checked) {
              setSelectedFiles(availableToSelectFiles.map((file) => file.id));
            } else {
              setSelectedFiles([]);
            }
          }}
        />
      )}

      <ul className="file-list">
        {availableToSelectFiles.map((file) => (
          <li
            className="file-list-item"
            key={`file-select-${file.id}`}
            data-testid={file.id}
          >
            <CheckboxFileSelection
              file={file}
              isChecked={selectedFileIds.includes(file.id)}
              setSelected={(e) => {
                if (e.target.checked) {
                  setSelectedFiles([...selectedFileIds, file.id]);
                } else {
                  setSelectedFiles(
                    selectedFileIds.filter((id) => id !== file.id),
                  );
                }
                setError('');
              }}
            />
          </li>
        ))}
      </ul>

      {currentConversationSelectedFiles.length > 0 && (
        <div className="files-applied">
          <hr />
          <h2>Files applied to this chat:</h2>
          <ul className="file-list">
            {currentConversationSelectedFiles.map((file) => (
              <li className="file-list-item" key={`file-select-${file.id}`}>
                <CheckboxFileSelection
                  file={file}
                  hideCheckbox={true}
                  isChecked={file.processingStatus !== 'deleted'}
                  setSelected={() => {}}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileSelector;
