import React, { useEffect, useState } from 'react';
import { Button, Modal, ConfirmationDialog } from 'ui';
import FileSelector from './FileSelector';
import {
  CurrentConversationStore,
  setFileContexts,
} from 'stores/current-conversation';
import api from 'services/api';

import { getPersonalFiles, markFilesForDeletion } from 'stores/user';
import { logger } from 'services/logs/logger';
import { setGlobalError } from 'stores/global-message';

const FileSelectorModal = ({
  fileSelectorVisible,
  setFileSelectorVisible,
  personalFiles,
}) => {
  const fileContexts = CurrentConversationStore.useState((s) => s.fileContexts);
  const [tempFileContexts, setTempFileContexts] = useState<any[]>([]);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  useEffect(() => {
    if (!fileSelectorVisible) {
      setTempFileContexts([]);
    }
  }, [fileSelectorVisible]);

  useEffect(() => {
    if (!personalFiles || !Array.isArray(personalFiles)) {
      setIsValid(true);
      return;
    }
    const loadingOrErrorFilesSelected =
      personalFiles.filter(
        (m) =>
          m.processingStatus !== 'done' &&
          m.processingStatus !== 'deleted' &&
          tempFileContexts.includes(m.id),
      ) || [];

    setIsValid(loadingOrErrorFilesSelected.length === 0);
  }, [personalFiles, tempFileContexts]);

  useEffect(() => {
    if (fileSelectorVisible) {
      if (!Array.isArray(fileContexts) || !Array.isArray(tempFileContexts)) {
        setIsDirty(false);
        return;
      }

      setIsDirty(tempFileContexts.length > 0);
    }
  }, [fileContexts, fileSelectorVisible, personalFiles, tempFileContexts]);

  const deleteFilesAction = async (fileIds: string[]) => {
    markFilesForDeletion(fileIds);
    setConfirmDelete(false);

    const deletedFilePromises = [] as Promise<any>[];
    for (const fileId of fileIds) {
      deletedFilePromises.push(api.deleteFile(fileId));
    }
    const results = await Promise.all(deletedFilePromises);
    const hasError = results.filter((result) => {
      return !result.ok;
    });
    if (hasError.length > 0) {
      setGlobalError('There was an error deleting one or more files');
      logger.error('Error deleting files');
    }
    setTempFileContexts([]);
    getPersonalFiles();
  };

  return (
    <>
      <Modal
        title={'Selected Files'}
        className="select-file-modal"
        open={fileSelectorVisible}
        onOk={() => {
          setFileSelectorVisible(false);
        }}
        onCancel={() => {
          setTempFileContexts(fileContexts);
          setFileSelectorVisible(false);
        }}
        footer={[
          <Button
            className="active full-size"
            key="fileselector_apply"
            data-testid="fileselector_apply"
            onClick={() => {
              setFileContexts(tempFileContexts);
              setFileSelectorVisible(false);
            }}
            disabled={!isValid || !isDirty}
          >
            Apply
          </Button>,
        ]}
      >
        <FileSelector
          fileList={personalFiles}
          selectedFileIds={tempFileContexts}
          currentConversationSelectedFileIds={fileContexts}
          setSelectedFiles={setTempFileContexts}
          setConfirmDelete={setConfirmDelete}
        />
        {isDirty && isValid && (
          <div
            className="info"
            data-testid="file-selection-change-info-message"
          >
            There are changes that have not been applied
          </div>
        )}
        {!isValid && (
          <div
            className="warning"
            data-testid="file-selection-change-warning-message"
          >
            Invalid file selected. Changes cannot be applied.
          </div>
        )}
      </Modal>
      {confirmDelete && (
        <ConfirmationDialog
          text="Are you sure you want to delete these files?"
          open={confirmDelete}
          confirmText="Delete"
          cancelText="Cancel"
          reportResponse={(hasConfirmed) => {
            if (hasConfirmed) {
              deleteFilesAction(tempFileContexts);
            } else {
              setConfirmDelete(false);
            }
          }}
        />
      )}
    </>
  );
};

export default FileSelectorModal;
