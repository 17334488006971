import { CurrentConversationStore } from 'stores/current-conversation';

export type FileType = {
  datetimeCreated: string;
  datetimeLastUpdated: string;
  displayFileName: string;
  id: string;
  originalFileUrl: string;
  privacyLevel: string;
  processedFileUrl: string;
  processingFailureComment: string;
  processingStatus: string;
  userId: string;
};

export type IndexType = {
  datetimeCreated: string;
  datetimeLastUpdated: string;
  description: string;
  displayName: string;
  id: string;
  numFiles: number;
  numWebsites: number;
  status: string;
};

export const hasFilesInProgress = (files: FileType[]): boolean => {
  return files.some((file) => file.processingStatus === 'in progress');
};

export const filterFilesInProgress = (files: FileType[]): FileType[] => {
  return files.filter((file) => file.processingStatus === 'in progress');
};

export const mapFileIdToFile = (
  fileId: string,
  files: FileType[],
): FileType | {} => {
  return files.find((file) => file.id === fileId) || {};
};

export const diffCurrentPendingFiles = (
  personalFiles: FileType[],
  fileContexts: string[],
): FileType[] => {
  const messages = CurrentConversationStore.getRawState().messages;

  const uniqFilesAlreadyApplied = new Set(
    messages.map((message) => message.associatedFileRecordIds).flat(),
  );

  const pendingFileIds = fileContexts.filter(
    (id) => !uniqFilesAlreadyApplied.has(id),
  );

  return personalFiles.filter((file) => pendingFileIds.includes(file.id));
};

export const getUniqueSelectedFileContextIdsFromMessages = (
  messages: any[],
) => {
  const selectedFileIds = (messages as any).reduce((acc, m) => {
    if (m.associatedFileRecordIds) {
      return [...acc, ...m.associatedFileRecordIds];
    }
    return acc;
  }, []);
  return Array.from(new Set(selectedFileIds));
};

export const sortFilesByLastUpdated = (files: FileType[]) => {
  return files.sort(
    (a, b) =>
      new Date(a.datetimeLastUpdated).getTime() -
      new Date(b.datetimeLastUpdated).getTime(),
  );
};

export const filterValidOnlyFileContexts = (
  files: any[],
  fileContexts: any[],
) => {
  const filteredFiles = getFilesByContexts(files, fileContexts);

  return filteredFiles.filter((f) => !f.processingFailureComment);
};

export const sanitizeFileContexts = (
  files: any[] = [],
  fileContexts: string[] = [],
): string[] => {
  const filteredFiles = getFilesByContexts(files, fileContexts);

  return filteredFiles
    .filter((f) => f.processingStatus === 'done')
    .filter((f) => !f.processingFailureComment)
    .map((f) => f.id);
};

export const getFilesByContexts = (
  files: FileType[] = [],
  contexts: string[] = [],
) => {
  let filterableFiles: FileType[] = files;

  if (!files.length) {
    filterableFiles = CurrentConversationStore.getRawState().fileContextFiles;
  }

  return filterableFiles.filter((file) => contexts.includes(file.id));
};

export const formatFileErrorMessages = (
  error: string,
  type = 'chit',
  metadata = { name: '' },
) => {
  if (!error) {
    return 'Error processing';
  }

  const errorMapping = {
    'Error occured while processing': {
      chit: 'Error processing',
      list: 'Error processing file',
      toast: `${metadata.name} could not be processed. It won’t be applied to your file context.`,
    },
    'Error occurred while processing file': {
      chit: 'Error processing',
      list: 'Error processing file',
      toast: `${metadata.name} could not be processed. It won’t be applied to your file context.`,
    },
    'File rejected: Security violation': {
      chit: 'Malicious file',
      list: 'Malicious file',
      toast: `${metadata.name} was found to be malicious. It won’t be applied to your file context.`,
    },
    'File max token length exceeded': {
      chit: 'Too much text',
      list: 'File has too much text',
      toast: `${metadata.name} contains too much text. It won’t be applied to your file context.`,
    },
    'File contains too much text': {
      chit: 'Too much text',
      list: 'File has too much text',
      toast: `${metadata.name} contains too much text. It won’t be applied to your file context.`,
    },
  };

  const hasErrorMap = errorMapping[error];

  if (hasErrorMap) {
    return errorMapping[error][type];
  }

  return error;
};

export function formatFileTypeExtensions(filename: string): [string, string] {
  const fileTypeMapping: { [key: string]: string } = {
    pptx: 'Powerpoint presentation',
    docx: 'Word document',
    txt: 'Text file',
    pdf: 'PDF',
    xlsx: 'Excel spreadsheet',
  };

  const fileExtension = filename?.split('.').pop()?.toLowerCase();

  if (fileExtension && fileTypeMapping[fileExtension]) {
    return [fileTypeMapping[fileExtension], fileExtension];
  } else {
    return ['Unknown file', 'unknown'];
  }
}
